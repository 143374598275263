import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import QR from "../assets/qr.jpeg";
import apiendpoints from "../apiendpoints.json";
import { useLocation } from "react-router-dom";
import Warning from "../components/Warning";
import { toast, ToastContainer } from "react-toastify";
import convertTo64 from "../service/converToBase64";
import Services from "../service/services.json";
import { fileTypeVaidation } from "../service/fileTypeValidation";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PaymentPage = () => {
  const query = useQuery();
  const servicename = query.get("service");
  const price = query.get("price");
  const duration = query.get("duration");
  const [name, setName] = useState("");
  const [loader, setLoader] = useState(false);
  const [pan, setPan] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [proof, setProof] = useState("");
  const [ahname, setAhName] = useState("");
  const [txnId, setTxnId] = useState("");
  const [servicePrice, setServicePrice] = useState(price || "000");
  const [serviceDuration, setServiceDuration] = useState(duration || "--");
  const [durationOptions, setDurationOptions] = useState([
    "--",
    "monthly",
    "quarterly",
    "halfyearly",
    "yearly",
  ]);
  const [service, setService] = useState(servicename || "");
  const proofRef = useRef(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true)
    try {
      const formData = {
        name,
        emailId: email,
        mobileNumber: phone,
        paymentProof: proof,
        accountHolderName: ahname,
        transactionId: txnId,
        service,
        serviceDuration,
        servicePrice,
        panno: pan,
      };
      if (!service.trim() || service === "Select A Service") {
        toast.error("Please Select A Service");
        return;
      }
      if (!serviceDuration.trim() || serviceDuration === "--") {
        toast.error("Please Select A Service Duration");
        return;
      }
      if (proofRef.current) {
        if(proofRef.current.value.split(".")[1] === "pdf" ){
          toast.error("Please Select A Image Only");
          proofRef.current.value = ""
          return;
        }
      }
      if(!pan.trim()){
        toast.error("Pan Number is Required");
        return;
      }
      let response = await fetch(
        `${apiendpoints.equityUrl}/api/PurchaseDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) {
        throw new Error("Something Went Wrong");
      }
      let data = await response.json();
      if (data) {
        toast.success("Purchase Initiated Successfully.");
      }
      if (response.ok) {
        setName("");
        setEmail("");
        setPhone("");
        setPan("");

        setTxnId("");
        setAhName("");
        setServicePrice("000");
        setServiceDuration("--");
        setDurationOptions([
          "--",
          "monthly",
          "quarterly",
          "halfyearly",
          "yearly",
        ]);
        setService("");
        if (proofRef.current) {
          proofRef.current.value = ""; // Clear file input
        }
      }
    } catch (error) {
      toast.error("Something Went Wrong")
    
    }
    setLoader(false)
  };

  const updateDurationOptions = (selectedService) => {
    let serviceData = Services.find((ser) => ser.heading === selectedService);
    if (serviceData) {
      const availableDurations = ["--"];
      if (serviceData.prices[0]?.monthly) availableDurations.push("monthly");
      if (serviceData.prices[1]?.quarterly)
        availableDurations.push("quarterly");
      if (serviceData.prices[2]?.halfyearly)
        availableDurations.push("halfyearly");
      if (serviceData.prices[3]?.yearly) availableDurations.push("yearly");

      setDurationOptions(availableDurations);
      setServiceDuration("--"); // Reset to default when service changes
      setServicePrice("000");
    } else {
      setDurationOptions(["--"]);
      setServiceDuration("--");
    }
  };

  const handlePriceChange = (event) => {
    const selectedDuration = event.target.value;
    const serviceData = Services.find((s) => s.heading === service);

    if (serviceData) {
      const selectedPrice = serviceData.prices
        .map((p) => p[selectedDuration])
        .filter((price) => price !== undefined)[0];
      setServicePrice(selectedPrice || "000");
    }
  };

  const handleServiceChange = (event) => {
    const selectedService = event.target.value;
    setService(selectedService);
    updateDurationOptions(selectedService); // Update duration options for selected service
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;

    if (!regex.test(value)) {
      // toast.error("Please Enter Numbers Only");
      return;
    } else if (value.length <= 10) {
      setPhone(value);
    } else {
      // toast.error("Please Enter a maximum of 10 digits");
      return;
    }
  };
  // Update the onChange for service duration
  const handleDurationChange = (event) => {
    const selectedDuration = event.target.value;
    setServiceDuration(selectedDuration);

    // Update service price based on selected duration
    const serviceData = Services.find((s) => s.heading === service);
    if (serviceData) {
      const selectedPrice = serviceData.prices
        .map((p) => p[selectedDuration])
        .filter((price) => price !== undefined)[0];
      setServicePrice(selectedPrice || "000");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="hidden lg:block">
        <Navbar />
        <Warning />
      </section>
      <section className="block lg:hidden">
        <Sidebar />
      </section>
      <section className="w-full my-10  md:my-20 lg:max-w-6xl lg:mx-auto p-4  py-10 rounded bg-gradient-to-b from-[#01295c] via-slate-900 to-[#000000] shadow-md space-y-6">
        <div className="w-full flex flex-col md:flex-row flex-wrap-reverse md:flex-nowrap items-center justify-center">
          <form className="flex-1 space-y-4" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {/* Email */}
              <div>
                <label className="block text-sm font-semibold text-white">
                  Email <sup className="text-red-500">*</sup>
                  <input
                    type="email"
                    className="mt-1 block w-full p-2.5 border rounded-lg shadow-sm bg-white text-gray-900"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </label>
              </div>

              {/* Name */}
              <div>
                <label className="block text-sm font-semibold text-white">
                  Name <sup className="text-red-500">*</sup>
                  <input
                    type="text"
                    className="mt-1 block w-full p-2.5 border rounded-lg shadow-sm bg-white text-gray-900"
                    placeholder="Your Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </label>
              </div>

              {/* Account Holder Name */}
              <div>
                <label className="block text-sm font-semibold text-white">
                  Account Holder Name <sup className="text-red-500">*</sup>
                  <input
                    type="text"
                    className="mt-1 block w-full p-2.5 border rounded-lg shadow-sm bg-white text-gray-900"
                    placeholder="Account Holder Name"
                    value={ahname}
                    onChange={(e) => setAhName(e.target.value)}
                    required
                  />
                </label>
              </div>

              {/* Phone */}
              <div>
                <label className="block text-sm font-semibold text-white">
                  Phone <sup className="text-red-500">*</sup>
                  <input
                    type="tel"
                    className="mt-1 block w-full p-2.5 border rounded-lg shadow-sm bg-white text-gray-900"
                    placeholder="Your Phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    required
                  />
                </label>
              </div>

              {/* Transaction ID */}
              <div>
                <label className="block text-sm font-semibold text-white">
                  Transaction ID <sup className="text-red-500">*</sup>
                  <input
                    type="text"
                    className="mt-1 block w-full p-2.5 border rounded-lg shadow-sm bg-white text-gray-900"
                    placeholder="Transaction ID"
                    value={txnId}
                    onChange={(e) => setTxnId(e.target.value)}
                    required
                  />
                </label>
              </div>

              {/* Payment Proof */}
              <div>
                <label className="block text-sm font-semibold text-white">
                  Payment Proof <sup className="text-red-500">*</sup>(img/png/jpg)
                  <input
                    type="file"
                    ref={proofRef}
                    className="mt-1 block w-full p-2.5 border rounded-lg shadow-sm bg-white text-gray-900"
                    onChange={async (e) => {
                      setProof(await fileTypeVaidation(e, "image"));
                    }}
                    accept="image/*"
                    required
                  />
                </label>
              </div>

              {/* Service Selection */}
              <div>
                <label className="block text-sm font-semibold text-white">
                  Service <sup className="text-red-500">*</sup>
                  <select
                    onChange={handleServiceChange}
                    value={service}
                    className="mt-1 block w-full p-2.5 border rounded-lg shadow-sm bg-white text-gray-900"
                  >
                    <option value="" disabled>
                      Select A Service
                    </option>
                    {Services.map((serv, i) => (
                      <option key={i} value={serv.heading}>
                        {serv.heading.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              {/* Service Duration */}
              <div>
                <label className="block text-sm font-semibold text-white">
                  Service Duration <sup className="text-red-500">*</sup>
                  <select
                    value={serviceDuration}
                    onChange={handleDurationChange} // Use the new handler here
                    className="mt-1 block w-full p-2.5 border rounded-lg shadow-sm bg-white text-gray-900"
                  >
                    {durationOptions.map((value, i) => (
                      <option key={i} value={value}>
                        {value.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div>
                <label className="block text-sm font-semibold text-white">
                  Pan <sup className="text-red-500">*</sup>
                  <input
                    type="text"
                    className="mt-1 block w-full p-2.5 border rounded-lg shadow-sm bg-white text-gray-900"
                    onChange={(e) => setPan(e.target.value)}
                    value={pan}
                    placeholder="Pan Number"
                    required
                  />
                </label>
              </div>
              {/* Service Price */}
              <div>
                <label className="block text-sm font-semibold text-white">
                  Price <sup className="text-red-500">*</sup>
                  <input
                    type="text"
                    className="mt-1 block w-full p-2.5 border rounded-lg shadow-sm bg-white text-gray-900"
                    value={servicePrice}
                    onChange={(e)=>setServicePrice(e.target.value)}
                  />
                </label>
              </div>
            </div>

            <div className="pt-4">
              <button
                type="submit"
                className="px-4 py-2 font-semibold text-white bg-primary rounded-lg shadow-md hover:bg-primary-dark/90 mx-auto my-5"
              >
                { !loader ?<span>Submit</span> : <span className="loader submit "></span>}
              </button>
            </div>
          </form>

          {/* Payment QR */}
          <div className="flex-1 flex items-center justify-center md:mx-2">
            <div className="border border-gray-600 p-5 rounded-md">
              <img
                src={QR}
                alt="QR Code"
                className="w-82 h-auto object-cover"
              />
            </div>
          </div>
        </div>
        <ToastContainer   position="top-right"
      autoClose={3000}
      limit={2}
      hideProgressBar={true}
      newestOnTop={true} />
        <div class="">
  <div class="mx-auto max-w-5xl py-5 sm:px-6 sm:py-6 lg:px-8">
    <div class="relative isolate overflow-hidden bg-gray-900 px-5 pt-8 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
      <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0" aria-hidden="true">
        <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" />
        <defs>
          <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
            <stop stop-color="#7775D6" />
            <stop offset="1" stop-color="#E935C1" />
          </radialGradient>
        </defs>
      </svg>
      
    </div>
  </div>
</div>
      </section>
    
      <Footer />
    </>
  );
};

export default PaymentPage;
