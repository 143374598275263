import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoFacebook,
  IoLogoYoutube,
  IoLogoLinkedin,
} from "react-icons/io5";

const Thanku = () => {
  return (
    <div className="w-full bg-gradient-to-r from-blue-100 to-blue-400 py-auto flex justify-center h-[80vh] items-center text-center px-4">
      <div className="bg-transparent w-full max-w-4xl justify-center text-center flex-col">
        <div className="flex flex-col justify-center text-center items-center">
          <div>
            <FaCheckCircle className="text-green-700 rounded-full text-6xl" />
          </div>
          <div>
            <h2 className="font-bold my-4 text-3xl sm:text-4xl md:text-5xl text-black">
              Thank you!
            </h2>
            <p className="font-semibold my-4 text-base sm:text-lg text-black">
              We have sent your free report to your inbox so it is easy to
              access. You can find more information on our website and social
              pages.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
          {/* Contact Card */}
          <div className="border shadow-xl w-full h-auto py-6 px-4 bg-blue-500 rounded-md">
            <p className="font-semibold mb-4 text-lg text-black">Contact With Us</p>
            <div className="mt-4 flex flex-wrap space-x-4 justify-center">
              <span className="hover:text-white text-white opacity-50 rounded-full text-3xl">
                <IoLogoTwitter />
              </span>
              <span className="hover:text-white text-white opacity-50 rounded-full text-3xl">
                <IoLogoInstagram />
              </span>
              <span className="hover:text-white text-white hover:shadow-lg hover:shadow-[#FDA512] rounded-full text-3xl">
                <a
                  href="https://www.facebook.com/profile.php?id=61565023105667&mibextid=ZbWKwL"
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IoLogoFacebook />
                </a>
              </span>
              <span className="hover:text-white text-white opacity-50 rounded-full text-3xl">
                <IoLogoYoutube />
              </span>
              <span className="hover:text-white text-white  opacity-50 rounded-full text-3xl">
                <IoLogoLinkedin />
              </span>
            </div>
          </div>
          {/* Website Card */}
          <div className="border shadow-xl w-full h-auto py-6 px-4 bg-blue-500 rounded-md flex flex-col justify-center items-center">
            <p className="font-semibold mb-4 text-lg text-black">Visit Our Website</p>
            <a
              href="/"
              className="border rounded-full px-4 py-2 text-white font-semibold hover:bg-white hover:text-blue-500 transition duration-300"
            >
              Visit Website
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thanku;
