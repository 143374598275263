/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHAComponent from "./GoogelRecaptcha/googleCaptcha";
import { MdOutlinePhoneCallback, MdPhoneAndroid, MdMail } from "react-icons/md";
import { AiOutlineMail } from "react-icons/ai";
import apiendpoints from "../apiendpoints.json";
import { motion } from "framer-motion";
import Thanku from "./HomePageComp/Thanku";

function ReachUs() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("Equity Research Mart - Enquiry");
  const [body, setBody] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");
  const [city, setcity] = useState("");
  const [isCaptchaValid, setisCaptchaValid] = useState(false);
  const [buttonEnable, setButton] = useState(true);
  const[showThank,setThank]=useState(false);
  const handleCaptchaChange = (value) => {
    setisCaptchaValid(!!value);
  };
  // const handlethanku=()=>{
  //   setThank(!showThank)
  // }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButton(false);
    setisCaptchaValid(false);

    const formData = {
      email: email,
      subject: subject,
      message: body,
      State: state,
      Country: country,
      City: city,
      mobile: phone,
      nameUser: name,
      entity: "equity",
    };

    try {
      if (window.gtag) {
        window.gtag('event', 'conversion', {
          send_to: 'AW-11496349823/wuRHCO7it_cZEP_A8ekq', // Replace with your Conversion ID and Label
        });
        console.log('Google Ads Conversion triggered');
      } else {
        console.warn('gtag is not loaded yet');
      }
      
    } catch (error) {
      console.error("Error in gtag:", error);
    }
    
    try {
      const response = await fetch(apiendpoints.enquiryUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          entity: "equity",
        },
        body: JSON.stringify(formData),
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to send mail:", response.status, errorText);
        toast.error("Failed to send mail");
      }
      setButton(true);
      setisCaptchaValid(true);
      setThank(true);
      toast.success("Thanks for contacting us!");

      setEmail("");
      setSubject("Equity Research Mart - Enquiry");
      setBody("");
      setPhone("");
      setName("");
      setcountry("");
      setstate("");
      setcity("");
      //  setisCaptchaValid(false);
    } catch (error) {
      console.error("Error sending mail:", error);
      toast.error("Failed to send mail");
      setButton(true);
      setisCaptchaValid(true);
    }
  };

  return (
    <div>
      <section className=" h-auto ">
        {/* <div
          id="map"
          className="relative h-[400px] overflow-hidden bg-cover bg-[50%] bg-no-repeat"
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12818.656175184382!2d77.4597714!3d28.6606137!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf1bb41c50fdf%3A0xe6f06fd26a7798ba!2sGhaziabad%2C%20Uttar%20Pradesh!5e1!3m2!1sen!2sin!4v1724216934182!5m2!1sen!2sin"
            width="100%"
            height="500"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>{" "}
        </div> */}
        <div className="container px-4 md:px-12 pb-24  mt-20 mx-auto">
          <div className="block rounded-lg bg-gradient-to-b from-[#01295c]  via-slate-900 to-[#000000]   px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  md:py-16 md:px-12 -mt-[70px] backdrop-blur-[30px] border border-gray-300 text-white">
            {/* <div className="w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:mb-5 xl:w-6/12">
                            <div className="align-start flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                                </svg>


                                <div className="ml-2 grow">
                                    <p className="text-sm text-neutral-800 italic underline underline-offset-4"> Distance from New Delhi railway station: 22 KM & Distance from Indra Gandhi International Airport: 8 KM
                                    </p>
                                </div>
                            </div>
                        </div> */}
            <motion.div
              className="relative mb-8"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <h1 className="text-4xl lg:text-5xl font-bold text-center mb-2">
                <span className="text-primary">Write </span>
                To{" "}
                <span className="underline underline-offset-8 decoration-[#2db928]">
                  Us
                </span>
              </h1>
            </motion.div>

            <p className="max-w-lg mb-4 md:px-6 lg:px-6 text-white xl:px-6 px-2 text-xl font-bold">
              Tell us about yourself
            </p>
            <div className="flex flex-col">
              <div className="mb-12 w-full md:px-3 lg:mb-0 lg:w-full lg:px-6">
                <form
                  onSubmit={handleSubmit}
                  className="mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2"
                >
                  <div className="mb-4 max-w-md ">
                    <label className="block mb-2 text-sm font-semibold ">
                      Email
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="shadow-sm bg-gray-100 border border-gray-300  text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal text-black focus:outline-none"
                        placeholder="username@gmail.com"
                        required
                      />
                    </label>
                  </div>
                  <div className="mb-4 max-w-md">
                    <label className="block mb-2 text-sm font-semibold ">
                      Name
                      <input
                        type="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="shadow-sm bg-gray-100 border border-gray-300 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none text-black"
                        placeholder="Your name "
                        required
                      />
                    </label>
                  </div>
                  <div className="mb-4 max-w-md">
                    <label className="block mb-2 text-sm font-semibold">
                      Phone number
                      <input
                        type="tel"
                        value={phone}
                        onChange={(e) => {
                          const regex = /^[0-9]*$/;
                          const value = e.target?.value;
                          if (!regex.test(value)) {
                            // toast.error("Please Enter Numbers Only");
                            return
                          } else if (value.length <= 10) {
                            setPhone(value); // update form data if valid
                            //console.log(value);
                          } else {
                            // toast.error("Please Enter a maximum of 10 digits");
                            return
                          }
                        }}
                        className="shadow-sm bg-gray-100 border border-gray-300  text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal text-black focus:outline-none"
                        placeholder="Your Phone number"
                        required
                      />
                    </label>
                  </div>
                  <div className="mb-4 max-w-md">
                    <label className="block mb-2 text-sm font-semibold ">
                      Country
                      <input
                        value={country}
                        onChange={(e) => setcountry(e.target.value)}
                        className="shadow-sm bg-gray-100 border border-gray-300  text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal text-black focus:outline-none"
                        placeholder="Country"
                        required
                      />
                    </label>
                  </div>

                  <div className="mb-4 max-w-md">
                    <label className="block mb-2 text-sm font-semibold ">
                      State
                      <input
                        value={state}
                        onChange={(e) => setstate(e.target.value)}
                        className="shadow-sm bg-gray-100 border border-gray-300  text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal text-black focus:outline-none"
                        placeholder="State"
                        required
                      />
                    </label>
                  </div>

                  <div className="mb-4 max-w-md">
                    <label className="block mb-2 text-sm font-semibold ">
                      City
                      <input
                        value={city}
                        onChange={(e) => setcity(e.target.value)}
                        className="shadow-sm bg-gray-100 border text-black border-gray-300 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                        placeholder="City"
                        required
                      />
                    </label>
                  </div>
                  <div className="mb-4 max-w-md">
                    <label className="block mb-2 text-sm font-semibold ">
                      Subject
                      <input
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        className="shadow-sm bg-gray-100 border border-gray-300  text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal text-black focus:outline-none"
                        placeholder="Message"
                        required
                      />
                    </label>
                  </div>

                  <div className="mb-4 md:col-span-2 lg:col-span-3">
                    <label className="block mb-2 text-sm font-semibold ">
                      Message
                      <textarea
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        className="shadow-sm bg-gray-100 border border-gray-300  text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal text-black focus:outline-none"
                        rows="6"
                        placeholder="Message"
                        required
                      ></textarea>
                    </label>
                  </div>

                  <div className="place-self-center md:col-span-2 lg:col-span-3">
                    <ReCAPTCHAComponent onChange={handleCaptchaChange} />
                  </div>

                  <button
                    type="submit"
                    className={`mb-6 w-full rounded text-white font-bold px-6 pt-2 pb-2 mt-3 text-lg  leading-normal lg:mb-0 max-w-sm md:col-span-2  lg:col-span-3  place-self-center ${
                      isCaptchaValid ? " bg-primary" : "bg-primary opacity-40"
                    }`}
                    disabled={!isCaptchaValid} 
                  >
                    {buttonEnable ? "Send" : "Sending..."}
                  </button>
                </form>
              </div>
              <div className="w-full  bg-primary/40 rounded-lg p-3 text-white grid grid-cols-1 lg:grid-cols-3 gap-5 justify-start  my-5">
                <div className="flex items-start">
                  <div className="inline-block rounded-full bg-primary p-2 text-white shrink-0">
                    <MdMail size={30} />
                  </div>
                  <div className="ml-6 ">
                    <p className="mb-1 font-bold ">You can also Email us at</p>

                    {/* First Email Address */}
                    <p className="text-sm text-accent-light italic underline underline-offset-4 decoration-green-500 ">
                      <a
                        href="mailto:info@equityresearchmart.in"
                        className="text-accent-light"
                      >
                        info@equityresearchmart.in
                      </a>
                    </p>

                    {/* Second Email Address */}
                    <p className="text-sm text-accent-light italic underline underline-offset-4 decoration-green-500 ">
                      <a className="text-accent-light">
                        compliance@equityresearchmart.in
                      </a>
                    </p>
                  </div>
                </div>

                <div className="flex items-start ">
                  <div className="inline-block rounded-full bg-primary p-2 text-white shrink-0">
                    <MdOutlinePhoneCallback size={30} />
                  </div>
                  <div className="ml-6 ">
                    <p className="mb-2 font-bold ">Call us on</p>
                    <p className="text-sm  italic mt-2">
                      <span className="font-semibold  underline-offset-2 decoration-green-500">
                        Mobile
                      </span>{" "}
                      <span className="text-accent-light underline underline-offset-4 decoration-green-500">
                        : +91 70044 93381
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex items-start ">
                  <div className="inline-block rounded-full bg-primary p-2 text-white shrink-0">
                    <MdPhoneAndroid size={30} />
                  </div>
                  <div className="ml-6 ">
                    <p className="mb-2 font-bold ">More</p>
                    <p className="text-sm  italic">
                      {" "}
                      You may also send a mail to
                    </p>
                    <p className="text-sm text-accent-light italic underline underline-offset-4 decoration-green-500">
                      <a
                        href="mailto:info@equityresearchmart.in"
                        className="text-wrap text-accent-light "
                      >
                        info@equityresearchmart.in
                      </a>
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              {/* <div className=" group mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12 ">
                    <div className="flex items-start">
                      <div className="srink-0">
                        <div className="inline-block rounded-full bg-primary md:p-4 p-2 group-hover:text-primary ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="md:h-6 md:w-6 text-white h-3 w-3"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold ">Address</p>
                        <p className="text-sm text-neutral-800  italic">
                          {" "}
                          514, Sector 15 UP <br />
                          14
                        </p>
                      </div>
                    </div>
                  </div> */}
            </div>

            <hr className="border-b-2 border-primary m-2" />

            <div className="flex justify-center bg-gradient-to-r from-cyan-200 to-green-400 p-2 rounded-md">
              <img src={"contact-us.svg"} alt="contact" className="h-56" />
            </div>
          </div>
        </div>
      </section>
      <ToastContainer  
      autoClose={3000}
      limit={2}
      hideProgressBar={true}
      newestOnTop={true} >
      </ToastContainer>
      {showThank && (
  <div className="fixed h-screen inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-4 rounded shadow-lg">
      
        <Thanku />
  
      
    </div>
  </div>
)}
    </div>
  );
}

export default ReachUs;
